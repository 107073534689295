import React from "react";
import CallToAction from "../containers/WebApp/CallToAction";

const Register = () => {
  return (
    <>
      <CallToAction />
    </>
  );
};

export default Register;
